import React from "react";
import { graphql, StaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class AboutAccordion extends React.Component {
  render() {
    return (
      <>
        {this.props.panels && (
          <div>
            {this.props.panels.map(panel => {
              return (
                <div className="about-accordion" key={panel.id}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h5 className="blue">{panel.acc_title}</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ReactMarkdown children={panel.acc_description} />
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        strapiAbout {
          about_accordion {
            accordions {
              id
              acc_title
              acc_description
            }
          }
        }
      }
    `}
    render={data => (
      <AboutAccordion panels={data.strapiAbout.about_accordion.accordions} />
    )}
  />
);
