import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Masthead from "../components/strapi/structure/Masthead";
import Twocol from "../components/strapi/structure/TwoCol";
import Craftsmanship from "../components/strapi/structure/Craftsmanship";
import AboutAccordion from "../components/strapi/about/AboutAccordion";
import Outropiggy from "../components/strapi/structure/Outropiggy";
import TestimonialSlider from "../components/testimonials/TestimonialSlider";

export default function About({ data }) {
  return (
    <Layout>
      <SEO title="About" />
      {data.allStrapiAbout.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.about_mh.mh_title}
            mhsubtitle={node.about_mh.mh_subtitle}
            mhbackground={node.about_mh.mh_bg.childImageSharp.fluid.originalImg}
          />
          <Twocol
            title={node.about_why.txtimg_title}
            description={node.about_why.txtimg_description}
            blueTitle={node.about_why.txtimg_bluetitle}
            blueLink={node.about_why.txtimg_bluelink}
            bgColor={node.about_why.txtimg_bgcolor}
            order={node.about_why.txtimg_order}
            rowimg={node.about_why.txtimg_img.childImageSharp.fluid.originalImg}
          />
          <Craftsmanship
            title={node.about_craftsmanship.craft_title}
            description={node.about_craftsmanship.craft_description}
          />
          <Twocol
            title={node.about_accordion.txtacc_title}
            description={node.about_accordion.txtacc_description}
            component={<AboutAccordion />}
            order={true}
          />
          <Twocol
            title={node.about_guarantee.txtimg_title}
            description={node.about_guarantee.txtimg_description}
            blueTitle={node.about_guarantee.txtimg_bluetitle}
            blueLink={node.about_guarantee.txtimg_bluelink}
            bgColor={node.about_guarantee.txtimg_bgcolor}
            order={node.about_guarantee.txtimg_order}
            smallsubtitle={node.about_guarantee.txtimg_subtitle}
            rowimg={node.about_guarantee.txtimg_img.publicURL}
          />
          <Outropiggy outro={node.about_outro.piggy_outro} />
          <TestimonialSlider />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query About {
    allStrapiAbout {
      edges {
        node {
          id # id of the node
          about_mh {
            mh_title
            mh_subtitle
            id
            mh_bg {
              publicURL
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          about_why {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          about_craftsmanship {
            id
            craft_title
            craft_description
          }
          about_accordion {
            id
            txtacc_title
            txtacc_description
          }
          about_guarantee {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_subtitle
            txtimg_img {
              publicURL
            }
          }
          about_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`;
